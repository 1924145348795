var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "box"
  }, [_vm._m(0), _c('div', {
    staticClass: "swiper"
  }, [_c('van-swipe', {
    ref: "swiper",
    staticClass: "my-swipe",
    attrs: {
      "autoplay": 2200,
      "indicator-color": "white",
      "touchable": true,
      "loop": true
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "indicator",
      fn: function () {
        return [_c('ul', {
          staticClass: "indicators"
        }, _vm._l(_vm.list, function (item, index) {
          return _c('li', {
            key: index,
            staticClass: "indicator",
            class: {
              active: _vm.checkActive(index)
            }
          });
        }), 0)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.list, function (image, index) {
    return _c('van-swipe-item', {
      key: index
    }, [_c('div', [_c('van-image', {
      attrs: {
        "width": "100%",
        "height": "5rem",
        "src": image.picUrl
      }
    })], 1)]);
  }), 1)], 1), _vm._m(1), _c('div', {
    staticClass: "textIntr"
  }, [_vm._v(" 公司创立于2017年，倡导“担当、共赢、创新、 诚信”，致力于为各行业提供数字化解决方案和系统 定制服务。采用主流先进的技术帮助客户实现高效快 速的业务目标，成为客户可持续信赖的合作伙伴。 公 司凭借深厚的行业经验积累、成熟的内部业务流程 稳定的客户响应机制，资深的专业技术团队、标准的 项目过程实施与质量管控系统，为客户提供优质的产 品和技术，全方位提供高品质服务。 ")]), _vm._m(2), _c('div', {
    staticClass: "product"
  }, [_vm._m(3), _vm._l(_vm.productList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "util01"
    }, [_c('img', {
      attrs: {
        "src": item.imgUrl,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "text01"
    }, [_c('div', [_vm._v(_vm._s(item.name))]), _c('div', [_vm._v(_vm._s(item.textDetail))])])]);
  })], 2), _c('div', {
    staticClass: "customDev"
  }, [_vm._m(4), _c('div', {
    staticClass: "unitTwo"
  }, [_vm._l(_vm.unitItemList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "unitItem"
    }, [_c('div', {
      staticClass: "serialNumber"
    }, [_vm._v(_vm._s(item.serialNumber))]), _c('img', {
      attrs: {
        "src": item.path,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "describe"
    }, [_vm._v(_vm._s(item.describe))])]);
  }), _c('div', {
    staticClass: "consult",
    on: {
      "click": function ($event) {
        return _vm.callPhone(_vm.phone);
      }
    }
  }, [_vm._v("立即咨询")])], 2)]), _c('div', {
    staticClass: "advantage"
  }, [_vm._m(5), _c('div', {
    staticClass: "typical"
  }, _vm._l(_vm.typeItmeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "typeItem"
    }, [_c('div', {
      staticClass: "typeTitle"
    }, [_c('img', {
      attrs: {
        "src": item.path,
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(item.typeTitle))])]), _c('div', {
      staticClass: "detailText"
    }, [_vm._v(_vm._s(item.detailText))])]);
  }), 0)]), _c('div', {
    staticClass: "partner"
  }, [_vm._m(6), _c('div', {
    staticClass: "unitpart"
  }, _vm._l(_vm.partItemList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "parItem"
    }, [_c('img', {
      attrs: {
        "src": item.path,
        "alt": ""
      }
    })]);
  }), 0)]), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "relation"
  }, [_vm._v("联系我们")]), _c('div', {
    staticClass: "footerText",
    on: {
      "click": function ($event) {
        return _vm.callPhone(_vm.phone);
      }
    }
  }, [_c('div', {
    staticClass: "iconfont icon-phone"
  }), _c('span', [_vm._v(_vm._s(_vm.phone))])]), _c('div', {
    staticClass: "footerText",
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard(_vm.emily);
      }
    }
  }, [_c('div', {
    staticClass: "iconfont icon-mb_youxiang"
  }), _vm._v(" " + _vm._s(_vm.emily) + " ")]), _c('div', {
    staticClass: "footerText",
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard(_vm.address);
      }
    }
  }, [_c('div', {
    staticClass: "iconfont icon-wxbdingwei"
  }), _c('span', {
    staticStyle: {
      "padding-left": "0.05rem"
    }
  }, [_vm._v(_vm._s(_vm.address))])]), _vm._m(7), _vm._m(8)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "leftLogo"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("../assets/image/logo.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "rightMenu"
  }, [_c('div', {
    staticClass: "iconfont icon-sousuo1"
  }), _c('div', {
    staticClass: "iconfont icon-_fenlei"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "introductory"
  }, [_c('div', {}, [_vm._v("公司简介")]), _c('div', [_vm._v("introduction")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dataNum"
  }, [_c('div', {
    staticClass: "colum"
  }, [_c('div', {
    staticClass: "columLeft"
  }, [_c('div', [_vm._v("6+")]), _c('div', [_vm._v("年行业研发经验")])]), _c('div', {
    staticClass: "columLeft"
  }, [_c('div', [_vm._v("500+")]), _c('div', [_vm._v("软件项目")])])]), _c('div', {
    staticClass: "colum"
  }, [_c('div', {
    staticClass: "columLeft"
  }, [_c('div', [_vm._v("1000+")]), _c('div', [_vm._v("客户服务案例")])]), _c('div', {
    staticClass: "columLeft"
  }, [_c('div', [_vm._v("50+")]), _c('div', [_vm._v("客户城市")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "introductory"
  }, [_c('div', {}, [_vm._v("软件产品")]), _c('div', [_vm._v("Software product")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "introductory"
  }, [_c('div', {}, [_vm._v("定制开发")]), _c('div', [_vm._v("Custom development")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "introductory"
  }, [_c('div', {}, [_vm._v("产品优势")]), _c('div', [_vm._v("Product Advantage")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "introductory"
  }, [_c('div', {}, [_vm._v("合作伙伴")]), _c('div', [_vm._v("Partners")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "qrcode"
  }, [_c('div', {
    staticClass: "codeLeft"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/image/rqcode01.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("关注公众号")])]), _c('div', {
    staticClass: "codeRight"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/image/qrcode02.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("扫码联系商务")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footbox"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/image/policeBadge.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v(" 湘ICP备2021006928号-1 ")])]);
}];
export { render, staticRenderFns };