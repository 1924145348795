import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 在主入口main.js文件中引入
import '../src/assets/style/basic.css'
import '../src/assets/style/public.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible/index.js'
import 'lib-flexible/flexible.js'
import '@/assets/style/index.scss'
import 'video.js/dist/video-js.css'
import '@/assets/inconfot/iconfont.css'

// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/index')
// Vue.use(VideoPlayer)

Vue.config.productionTip = false
Vue.use(Vant)
// Vue.prototype.$video = Video
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
