import Vue from 'vue';
import { Lazyload } from 'vant';
import { Toast } from 'vant';
Vue.use(Lazyload);
Vue.use(Toast);
export default {
  name: 'HomeView',
  components: {
    // personDetail
  },
  created() {
    document.title = '小康科技官网';
    this.clientWidth = document.documentElement.clientWidth;
    console.log('高度', this.clientWidth);
  },
  data() {
    return {
      timer: null,
      current: 0,
      phone: '13762381553',
      emily: 'hnjsxk@sina.com',
      address: "湖南省株洲市第二工人文化宫",
      images: [require('@/assets/image/logo.png'), require('@/assets/image/logo.png')],
      list: [
      // {
      //   name: '1',
      //   picUrl:require("@/assets/image/demo01.png")
      // },
      {
        name: '2',
        picUrl: require('@/assets/image/demo02.png')
      }, {
        name: '3',
        picUrl: require('@/assets/image/demo03.png')
      }, {
        name: '4',
        picUrl: require('@/assets/image/demo04.png')
      }, {
        name: '5',
        picUrl: require('@/assets/image/demo05.png')
      }, {
        name: '6',
        picUrl: require('@/assets/image/demo06.png')
      }, {
        name: '7',
        picUrl: require('@/assets/image/demo07.png')
      }],
      unitItemList: [{
        serialNumber: '01',
        path: require('../assets/image/icon01.png'),
        describe: '品牌网站建设'
      }, {
        serialNumber: '02',
        path: require('../assets/image/icon02.png'),
        describe: '微信定制开发'
      }, {
        serialNumber: '03',
        path: require('../assets/image/icon03.png'),
        describe: '移动应用定制'
      }, {
        serialNumber: '04',
        path: require('../assets/image/icon04.png'),
        describe: '数据大屏定制'
      }, {
        serialNumber: '05',
        path: require('../assets/image/icon05.png'),
        describe: '产品咨询定制'
      }, {
        serialNumber: '06',
        path: require('../assets/image/icon06.png'),
        describe: '营销推广策略'
      }, {
        serialNumber: '07',
        path: require('../assets/image/icon07.png'),
        describe: '品牌视觉设计'
      }, {
        serialNumber: '08',
        path: require('../assets/image/icon08.png'),
        describe: '技术运维管理'
      }],
      typeItmeList: [{
        typeTitle: '程序稳定',
        path: require('../assets/image/advan01.png'),
        detailText: '多维度系统级稳定性保障,打造99.95%高度可用SaaS平台。'
      }, {
        typeTitle: '灵活可变',
        path: require('../assets/image/advan02.png'),
        detailText: '管理后台可实现流媒体服务器自由配置,打破服务商及地区限制。'
      }, {
        typeTitle: '品牌建档',
        path: require('../assets/image/advan03.png'),
        detailText: '针对新版本系统,整体提供详细搭建部署文件,专业团队一对一搭建部署指导。'
      }],
      partItemList: [{
        path: require('../assets/image/part01.png')
      }, {
        path: require('../assets/image/part02.png')
      }, {
        path: require('../assets/image/part03.png')
      }, {
        path: require('../assets/image/part04.png')
      }, {
        path: require('../assets/image/part05.png')
      }, {
        path: require('../assets/image/part06.png')
      }],
      productList: [{
        name: '综合场馆管理系统',
        imgUrl: require('@/assets/image/demo02.png'),
        textDetail: '系统功能涵盖面广，能够满足场馆的日常应用需求，提高工作效能。安全、高效、流畅。节约运营成本、提高用户体验感。'
      }, {
        name: '预付款资金监管管理系统',
        imgUrl: require('@/assets/image/demo03.png'),
        textDetail: '是人民银行对预付款资金监管的要求。可合法对预付资金进行收款、监管、清分。从根本上防止小金库形成。系统可指定监管户资金定向消费、指定清分、多商户清分。T+1'
      }, {
        name: '基层工会管理系统',
        imgUrl: require('@/assets/image/demo06.png'),
        textDetail: '满足基层工会数字化加快建设需求，解决基层工会建设管理实际困难。采用符合社会当下、满足不同要求、方便快捷的方式发放职工福利。'
      }, {
        name: '商场营销管理系统',
        imgUrl: require('@/assets/image/demo04.png'),
        textDetail: '融合管理资源可视化技术与完善的平台管理，实现铺位资源数字化、租务结算自动化、运营服务高效化、招商赋能个性化、审批流程无感化，贯穿商业管理全生命周期。'
      },
      // {
      //   name:'小程序投票系统',
      //   imgUrl:require("@/assets/image/demo01.png"),
      //   textDetail:"专业、安全、稳定的投票活动平台搭建。可自行上传图片、视频，支持多种格式。无广告"
      // },
      {
        name: '合同管理系统',
        imgUrl: require('@/assets/image/demo07.png'),
        textDetail: '合同管理系统，提供了从合同前期，相对方管理、模板管理，到便捷起草、合规审批、线上签署、履约控制、数字化归档，合同全生命周期的数字化管理平台。'
      }]
    };
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.clientWidth = document.documentElement.clientWidth;
      // console.log('宽度',this.clientWidth);
    });
  },

  methods: {
    goDetail(id) {
      // this.num = id
      // console.log(this.num);
      // this.$router.push(`/detail/${this.num}`)
    },
    checkActive(index) {
      return index === this.current; // current为监听的轮播index
    },

    onChange(index) {
      this.current = index;
    },
    callPhone(phoneNumber) {
      console.log(phoneNumber, 'phoneNumber');
      window.location.href = "tel://" + phoneNumber;
    },
    copyToClipboard(text) {
      var textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.opacity = 0;
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      if (textarea) {
        Toast('已复制到粘贴板');
      }
    }
  }
};