import HomeViewVue from './views/HomeView.vue';
export default {
  data() {
    return {
      num: ''
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HomeViewVue
  },
  mounted() {
    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      });
    };
  },
  methods: {}
};